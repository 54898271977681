import {CustomerModel} from './../../../models/CustomerModel'
import {CustomerChatUserModel} from '../../../models/eva/CustomerChatUserModel'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {GetCustomerList} from './EvaCRUD'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
interface IPagesState {
  user?: {data: CustomerChatUserModel; ticketCode: string}
  attendees?: {data: GlobalSearchModel<CustomerModel>; ticketCode: string}
  token?: string
}

const initialAuthState: IPagesState = {
  user: undefined,
  attendees: undefined,
  token: undefined,
}

export const actionTypes = {
  user: {
    save: '[EVA] SAVE USER',
    clear: '[EVA] CLEAR USER',
    token: '[EVA] SAVE TOKEN',
  },

  attendees: {
    search: {
      execute: '[EVA] SEARCH ATTENDEES',
      success: '[EVA] SEARCH ATTENDEES SUCCESS',
      failed: '[EVA] SEARCH ATTENDEES FAILED',
    },
  },
}

export const reducer = persistReducer(
  {storage, key: 'webapp-eva', whitelist: ['user', 'attendees']},
  (state: IPagesState = initialAuthState, action: Partial<any>) => {
    switch (action.type) {
      case actionTypes.user.save: {
        const _payload = action.payload
        return {...state, user: _payload, token: _payload.data.token}
      }

      // case actionTypes.user.token: {
      //   const user = action.payload
      //   return {...state, user}
      // }

      case actionTypes.user.clear: {
        return {...state, user: undefined}
      }

      case actionTypes.attendees.search.success: {
        const attendees = action.payload?.data
        return {...state, attendees}
      }

      default:
        return state
    }
  }
)

export const actions = {
  saveUser: (data: CustomerChatUserModel, ticketCode: string) => ({
    type: actionTypes.user.save,
    payload: {data, ticketCode},
  }),
  // saveUserToken: (token: string) => ({
  //   type: actionTypes.user.token,
  //   payload: {token},
  // }),

  clearUser: () => ({
    type: actionTypes.user.clear,
  }),
  attendees: {
    search: () => ({type: actionTypes.attendees.search.execute}),
    searchSuccess: (data: GlobalSearchModel<CustomerModel>) => ({
      type: actionTypes.attendees.search.success,
      payload: {data},
    }),
    searchFailed: () => ({type: actionTypes.attendees.search.failed}),
  },
}

export function* saga() {
  yield takeLatest([actionTypes.attendees.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['eva-attendee-list'])
      const {data}: {data: GlobalSearchModel<CustomerModel>} = yield GetCustomerList(filter)
      yield put(actions.attendees.searchSuccess(data))
    } catch (e) {
      yield put(actions.attendees.searchFailed())
    }
  })

  // yield takeLatest([actionTypes.user.save], function* refresh() {
  //   try {
  //     const token: string = yield select((state) => state.eva.data.token)
  //     // const {data}: {data: GlobalSearchModel<CustomerModel>} = yield GetCustomerList(filter)
  //     yield put(actions.saveUserToken(token))
  //   } catch (e) {
  //     // yield put(actions.attendees.searchFailed())
  //   }
  // })
}
