import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {getPollsByCode, submitPollsAnswers} from '../../redux/EvaCRUD'
import {useHistory, useParams} from 'react-router-dom'
import {
  PollAnswerModel,
  PollQuestionsRatingModel,
  PollRatingModel,
  PollSubmitParams,
} from '../../../../models/eva/PollRatingModel'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {Button} from '../../../../components/inputs/Button'

import InputRatingPolls from '../../components/InputRating/Inputrating'
import {RatingInput} from '../../../../components/inputs/RatingInput/RatingInput'
import {TextAreaInput} from '../../../../components/inputs'
import {getAnswerValueAndDisability} from '../../hooks/usePolls'
import {LoadingSpinner} from '../../../../components/utils/LoadingSpinner'

const PoolsRatingPageConsolidated = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [currentPoll, setCurrentPoll] = useState<PollRatingModel>()
  const [pollQuestions, setQuestions] = useState<PollQuestionsRatingModel[]>([])
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [pollAnswers, setPollAnswers] = useState<PollSubmitParams>({pollAnswers: []})
  const history = useHistory()
  const {code} = useParams<{code: string}>()
  const {pushError, push} = useAlerts()

  const goTo = useCallback(
    (path: string) => {
      history.replace(path)
    },
    [history]
  )

  const refreshQuestionslist = useCallback(
    async (ticketCode) => {
      const {data} = await getPollsByCode({code, ticketCode})
      setCurrentPoll(data)
    },
    [code]
  )

  useEffect(() => {
    if (ticketCode) refreshQuestionslist(ticketCode)
  }, [refreshQuestionslist, ticketCode])

  useEffect(() => {
    if (currentPoll && currentPoll.pollQuestions) {
      currentPoll.pollQuestions.forEach((question) => {
        question.pollOptions.forEach((item) => {
          item['value'] = false
          item['disable'] = false
        })
      })
      setQuestions(currentPoll.pollQuestions)
    }
  }, [currentPoll])

  const handleNextClick = useCallback(() => {
    // setCurrentStep(currentStep + 1)
    setCurrentStep((currentStep) => {
      return currentStep + 1
    })
    setIsFirstRender(true)
  }, [])

  const handlePreviousClick = useCallback(() => {
    // setCurrentStep(currentStep - 1)
    setCurrentStep((currentStep) => {
      return currentStep - 1
    })
    setIsFirstRender(true)
  }, [])

  const handleInputChange = useCallback(
    (questionCode: string, answerCode: string) => {
      setIsFirstRender(false)
      let oldResult = [...pollQuestions]
      const existedQuestion = oldResult.find(
        (item: PollQuestionsRatingModel) => item.code === questionCode
      )
      if (existedQuestion) {
        const existedanswer = existedQuestion.pollOptions.find(
          (item: PollAnswerModel) => item.code === answerCode
        )
        let checkedAnswers: PollAnswerModel[] = []
        if (existedanswer) {
          existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
            if (element.code === answerCode) element.value = !element.value // change the value
            if (element.value === true) checkedAnswers.push(element) // save value if true
            return null
          })
        }
        if (existedQuestion.isMultiple) {
          // if multible selection
          if (checkedAnswers.length === existedQuestion.maximumOptions) {
            // if we reach the limit
            existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
              checkedAnswers.forEach((_checked) => {
                if (element.code !== _checked.code && element.value !== true) {
                  // if the element is not one of the saved values(true)
                  element.disable = true
                } else element.disable = false
              })
            })
          } else {
            existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
              element.disable = false
            })
          }
        } else {
          existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
            if (element.code === answerCode) {
              element.value = true
            } else element.value = false
          })
        }

        setQuestions(oldResult)
      }
    },
    [pollQuestions]
  )

  const handleVotingSubmit = useCallback(async () => {
    let oldPollAnswers = {...pollAnswers}
    pollQuestions.forEach((_question) => {
      let answersArray: string[] = []
      _question.pollOptions.forEach((_answer) => {
        if (_answer.value) {
          answersArray.push(_answer.code)
        }
      })
      if (answersArray.length > 0) {
        oldPollAnswers.pollAnswers.push({
          pollQuestionCode: _question.code,
          pollOptionCodes: answersArray,
        })
      } else {
        oldPollAnswers.pollAnswers.push({
          pollQuestionCode: _question.code,
          other: _question.textAnswer,
        })
      }
    })

    setPollAnswers(oldPollAnswers)
    try {
      if (ticketCode) {
        const result = await submitPollsAnswers({ticketCode, code, body: oldPollAnswers})
        if (result) {
          push({
            message: 'Thank you for your participation.',
            timeout: 3000,
            variant: 'success',
          })
          goTo('/polls')
        }
      }
    } catch (err) {
      pushError(err)
      goTo('/polls')
    }
  }, [code, goTo, pollAnswers, pollQuestions, push, pushError, ticketCode])

  const handleRatingValue = useCallback(
    (questionCode: string, value: number) => {
      let oldPollAnswers = {...pollAnswers}
      const existedQuestion = oldPollAnswers.pollAnswers.find(
        (item) => item.pollQuestionCode === questionCode
      )
      if (existedQuestion) {
        existedQuestion.rating = value
      } else {
        oldPollAnswers.pollAnswers.push({
          pollQuestionCode: questionCode,
          rating: value,
        })
      }
      setPollAnswers(oldPollAnswers)
    },
    [pollAnswers]
  )

  const handleRatingText = useCallback(
    (questionCode) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      let oldResult = [...pollQuestions]
      const existedQuestion = oldResult.find(
        (item: PollQuestionsRatingModel) => item.code === questionCode
      )

      if (existedQuestion) {
        existedQuestion.textAnswer = e.target.value
        setQuestions(oldResult)
      }
    },
    [pollQuestions]
  )

  const renderQuestionAndAnswer = useMemo(() => {
    if (pollQuestions.length) {
      let _question = pollQuestions[currentStep]
      if (_question.pollOptions.length && isFirstRender) {
        _question.pollOptions = _question.pollOptions.map((itm, i) => {
          if (i === 0) {
            itm.value = true
          }
          return itm
        })
      }
      return (
        <div className='text-start canela-regular'>
          <h1 className='mb-5' style={{fontSize: '35px'}}>{`${_question.name}`}</h1>
          <p className='mb-5' style={{fontSize: '24px'}}>
            {_question.description}
          </p>
          <div className=''>
            <div style={{}}>
              {_question.pollOptions &&
                _question.pollOptions.map((_answer) => {
                  return (
                    <div key={_answer.code} className={['form-check mb-4 ps-1'].join(' ')}>
                      {_question.type !== 'rating' && !_question.isMultiple && (
                        <>
                          <InputRatingPolls
                            answerCode={_answer.code}
                            questionCode={_question.code}
                            name={_question.name!}
                            answerText={_answer.name!}
                            type='radio'
                            result={pollQuestions}
                            onChange={handleInputChange}
                          />
                        </>
                      )}

                      {_question.type !== 'rating' && _question.isMultiple && (
                        <>
                          <InputRatingPolls
                            answerCode={_answer.code}
                            questionCode={_question.code}
                            name={_question.name!}
                            answerText={_answer.name!}
                            type='checkBox'
                            result={pollQuestions}
                            onChange={handleInputChange}
                            disabled={
                              getAnswerValueAndDisability(
                                _question.code,
                                _answer.code,
                                pollQuestions
                              )[1]
                            }
                          />
                        </>
                      )}
                    </div>
                  )
                })}
              {_question.type === 'rating' && (
                <div className=''>
                  <RatingInput
                    width={30}
                    height={30}
                    className='mx-2'
                    questionCode={_question.code}
                    count={5}
                    onChange={handleRatingValue}
                  />
                </div>
              )}
              {_question.type === 'text' && (
                <div className=''>
                  <TextAreaInput
                    label=''
                    placeholder='Enter text here'
                    style={{
                      height: '300px',
                      fontSize: '20px',
                    }}
                    onChange={handleRatingText(_question.code)}
                    value={_question.textAnswer}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }, [
    currentStep,
    pollQuestions,
    handleInputChange,
    handleRatingValue,
    handleRatingText,
    isFirstRender,
  ])

  const actions = useMemo(() => {
    const hasPrevious = currentStep > 0
    const hasNext = currentStep < pollQuestions.length - 1

    let hasAnswer = true
    if (pollQuestions.length && hasNext) {
      const question = pollQuestions[currentStep]
      if (question.type === 'vote') {
        const ans = question.pollOptions.find((_item) => _item.value)
        if (ans) {
          hasAnswer = false
        }
      } else if (question.type === 'text') {
        if (question.textAnswer) {
          hasAnswer = false
        }
      }
    }

    return (
      <div className='d-flex gap-5'>
        {hasPrevious && (
          <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
            Previous
          </Button>
        )}
        {hasNext && (
          <Button
            className='w-100'
            variant='primary'
            type='button'
            onClick={handleNextClick}
            disabled={hasAnswer}
          >
            Next
          </Button>
        )}
        {!hasNext && (
          <Button className='w-100' variant='primary' type='button' onClick={handleVotingSubmit}>
            Submit
          </Button>
        )}
      </div>
    )
  }, [handlePreviousClick, handleNextClick, pollQuestions, currentStep, handleVotingSubmit])

  return (
    <div className='w-100 p-10 h-100 d-flex flex-column'>
      {pollQuestions.length ? (
        <>
          <div style={{height: '300px'}}>{renderQuestionAndAnswer}</div>
          <div className='mt-auto'>{actions}</div>
        </>
      ) : (
        <LoadingSpinner loading={true} spinnerOnly />
      )}
    </div>
  )
}

export {PoolsRatingPageConsolidated}
