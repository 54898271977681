import {FormikContextType} from 'formik'
import {TextInput} from '../../../../../components/inputs'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {CustomerModelType} from '../../../../../models/CustomerModel'
import {SelectInput, SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {useFormikStringInputHelpers} from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import {GetRegistrationCities, GetRegistrationCoutries} from '../../redux/CustomerRegistrationCRUD'
import {CustomFieldOptionsModel} from '../../../../../models/system/CustomFieldModel'
import {useOnChange} from '../../../../../components/hooks/useOnChange'

export interface RegistrationCustomerInformationValues {
  salutation: string
  nationality: string
  residence: string
  name: string
  lastName: string
  email: string
  designation: string
  customerOrganization: string
  type: CustomerModelType
  eventCode?: string
  gender: string
  firstName: string
  mobileNumber: string
  state: string
  degree: string
  university: string
  universityLocation: string
  graduatedYear: string
  formType: string
  cityEmpty?: boolean
}

export interface RegistrationCustomerInformationProps<
  T extends RegistrationCustomerInformationValues
> {
  formik: FormikContextType<T>
  degreeOptions: CustomFieldOptionsModel[]
}

export const currenDate = new Date()

export const RegistrationCustomerInformation = <T extends RegistrationCustomerInformationValues>({
  formik,
  degreeOptions,
}: RegistrationCustomerInformationProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)
  const stringInputHelpers = useFormikStringInputHelpers(formik)
  const [countryList, setCountryList] = useState<ISOCountryModel[]>([])
  const [cityList, setCityList] = useState<ISOCountryModel[]>([])

  const resetCountryList = useCallback(async () => {
    const {data} = await GetRegistrationCoutries()
    setCountryList(data)
  }, [])

  const resetCityList = useCallback(
    async (country: string) => {
      const {data} = await GetRegistrationCities({
        countryCode: country,
      })
      if (!data.length) {
        if (formik.values.formType === 'Speaker') {
          formik.setFieldValue('cityEmpty', false)
        }
      } else {
        if (formik.values.formType === 'Speaker') {
          formik.setFieldValue('cityEmpty', true)
        }
      }
      setCityList(data)
    },
    [formik.values.formType]
  )

  const handleResidenceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const residence = e.target.value
    formik.setFieldValue('residence', residence)
    formik.setFieldValue('state', '')
  }

  const handleGenderSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const gender = e.target.value
    formik.setFieldValue('gender', gender)
  }

  const handleNationalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nationality = e.target.value
    formik.setFieldValue('nationality', nationality)
  }

  // const handleDegreeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const degree = e.target.value
  //   formik.setFieldValue('degree', degree)
  // }

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value
    formik.setFieldValue('state', state)
  }

  // const handleGraduated = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     let gradYear = e.target.value
  //     if (Number(gradYear) <= 0 || Number(gradYear) > currenDate.getFullYear()) {
  //       gradYear = '2000'
  //     }
  //     formik.setFieldValue('graduatedYear', gradYear)
  //   },
  //   [formik]
  // )

  const countryLists = useMemo(() => {
    return countryList.map<SelectInputItem>((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, [countryList])

  // const degreeLists = useMemo(() => {
  //   return degreeOptions.map<SelectInputItem>((degree) => ({
  //     label: degree.name,
  //     value: degree.code,
  //   }))
  // }, [degreeOptions])

  const cityLists = useMemo(() => {
    return cityList.map<SelectInputItem>((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, [cityList])

  useEffect(() => {
    resetCountryList()
  }, [resetCountryList])

  useOnChange(formik.values.residence, () => {
    if (formik.values.residence) {
      resetCityList(formik.values.residence)
    }
  })
  return (
    <div className='row form-custom'>
      <div className='col-6'>
        <TextInput label='Salutation' isRequired {...textInputHelpers.getProps('salutation')} />
      </div>

      <div className='col-sm-6 col-12'>
        <TextInput
          label='First Name'
          readOnly
          isRequired
          {...textInputHelpers.getProps('firstName')}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput
          label='Last Name'
          readOnly
          isRequired
          {...textInputHelpers.getProps('lastName')}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput label='Email' readOnly isRequired {...textInputHelpers.getProps('email')} />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput label='Designation' isRequired {...textInputHelpers.getProps('designation')} />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput
          label='Organization'
          isRequired
          {...textInputHelpers.getProps('customerOrganization')}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <SelectInput
          isRequired
          placeholder='Select Country of Residence'
          label='Country of Residence'
          items={countryLists}
          value={formik.values.residence}
          onChange={handleResidenceChange}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <SelectInput
          isRequired={
            formik.values.formType === 'Speaker' ? (cityList.length ? true : false) : false
          }
          placeholder='Select City'
          label='City'
          items={cityLists}
          value={formik.values.state}
          onChange={handleStateChange}
        />
      </div>
      {/* {formik.values.formType === 'Speaker' && (
        <>
          <div className='col-sm-6 col-12'>
            <SelectInput
              isRequired
              placeholder='Select Education'
              label='Education Level'
              items={degreeLists}
              value={formik.values.degree}
              onChange={handleDegreeChange}
            />
          </div>
          <div className='col-sm-6 col-12'>
            <TextInput
              isRequired
              label='Year of Graduation'
              type='number'
              min={1000}
              max={currenDate.getFullYear()}
              // {...textInputHelpers.getProps('graduatedYear')}
              errorMessage={formik.errors.graduatedYear as string}
              value={formik.values.graduatedYear}
              isTouched={formik.errors.graduatedYear ? true : false}
              onChange={handleGraduated}
            />
          </div>
          <div className='col-sm-6 col-12'>
            <TextInput
              isRequired
              label='School or University Name'
              {...textInputHelpers.getProps('university')}
            />
          </div>
          <div className='col-sm-6 col-12'>
            <TextInput
              isRequired
              label='School or University Location (City)'
              {...textInputHelpers.getProps('universityLocation')}
            />
          </div>
        </>
      )} */}

      <div className='col-sm-6 col-12'>
        <SelectInput
          isRequired
          placeholder='Select Nationality'
          label='Nationality'
          items={countryLists}
          value={formik.values.nationality}
          onChange={handleNationalityChange}
        />
      </div>
      <div className='col-sm-6 col-8'>
        <SelectInput
          placeholder='Select Gender'
          isRequired
          label='Gender'
          items={GENDER_SELECT_ITEMS}
          {...formik.getFieldProps(`gender`)}
          value={formik.values.gender}
          onChange={handleGenderSelection}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <MobileNumberInput
          isRequired
          label='Mobile Number'
          helperMessage='Ex: +971551234567'
          {...stringInputHelpers.getProps('mobileNumber')}
        />
      </div>
    </div>
  )
}

export const STEP_CUSTOMER_KEYS: Array<keyof RegistrationCustomerInformationValues> = [
  'designation',
  'email',
  'name',
  'lastName',
  'customerOrganization',
  'gender',
  'residence',
  'nationality',
  'salutation',
  'mobileNumber',
  'degree',
  'graduatedYear',
  'university',
  'universityLocation',
  'state',
  'cityEmpty',
]

const GENDER_SELECT_ITEMS: SelectInputItem[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  // {
  //   label: 'Prefer not to say',
  //   value: 'P',
  // },
]
