import {useCallback, useEffect, useMemo, useState} from 'react'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {PollModel} from '../../../models/eva/PollModel'
import {useHistory} from 'react-router'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {GetCustomerByTicketCode, GetCustomerByTicketCodeNew, GetPolls} from '../redux/EvaCRUD'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
// import {TicketAuth} from '../components/TicketAuth/TicketAuth'
import NavigationCell from '../components/NavigationCell/NavigationCell'
import {Button} from '../../../components/inputs/Button'
import {CustomerRegistrationFormLogoHeader, investopiaBG} from '../../../../config/logos'
import {QRCodeModalInput} from '../../../components/inputs/QRCode/QRCodeInputModal'
import {useModalState} from '../../../components/modals/useModalState'
import {QrCodeDataParser} from '../../../utils/Qr/QrCodeDataParser'
import {useDispatch} from 'react-redux'
import {actions} from '../redux/EvaRedux'
import {SwalUtils} from '../../../utils/SwalUtils'

const PollsPage = () => {
  const dispatch = useDispatch()
  const [allPolls, setAllPolls] = useState<PollModel[]>([])
  const {hide: hideQrScanner, isOpen: isQrScannerOpen, open: openQrScanner} = useModalState()
  const history = useHistory()
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const {pushError} = useAlerts()
  const refreshPollsList = useCallback(async () => {
    try {
      if (EVENT_CODE) {
        const {data} = await GetPolls(EVENT_CODE)
        setAllPolls(data)
      }
    } catch (e) {
      pushError(e)
    }
  }, [pushError])

  const goTo = useCallback(
    (path: string) => {
      history.replace(path)
    },
    [history]
  )

  useEffect(() => {
    // if (ticketCode) refreshPollsList(ticketCode)
    refreshPollsList()
  }, [ticketCode, refreshPollsList])

  const handleGoToRating = useCallback(
    (poll: PollModel) => {
      history.push(`/polls/${poll.code}`, {
        from: '/polls',
        selectedPoll: poll,
      })
    },
    [history]
  )

  const handleScanButtonClick = useCallback(() => {
    openQrScanner()
  }, [openQrScanner])

  const pollsMemo = useMemo(() => {
    return (
      allPolls &&
      allPolls.length > 0 &&
      allPolls.map((poll, index, array) => {
        const isLast = index === array.length - 1
        return (
          <div key={poll.code}>
            <NavigationCell
              goTo={handleGoToRating}
              data={poll}
              title={poll.name}
              description={poll.description}
            />
            {!isLast && (
              <div className='my-4'>
                <HorizontalDivider />
              </div>
            )}
          </div>
        )
      })
    )
  }, [allPolls, handleGoToRating])

  const pollsMemo1 = useMemo(() => {
    return (
      allPolls &&
      allPolls.length > 0 &&
      allPolls.map((poll, index, array) => {
        const isLast = index === array.length - 1
        return (
          <div key={poll.code} className='text-center canela-regular'>
            <img
              alt={CustomerRegistrationFormLogoHeader.alt}
              src={CustomerRegistrationFormLogoHeader.src}
              className='img-fluid w-300px w-100'
              style={{marginBottom: '50px'}}
            />
            <h1 className='mb-10' style={{fontSize: '35px'}}>{`${poll.name}`}</h1>
            <h4
              className='mb-5'
              style={{lineHeight: '30px', fontSize: '24px'}}
            >{`${poll.description}`}</h4>

            <Button
              size='lg'
              variant='primary'
              onClick={handleScanButtonClick}
              uppercase={false}
              tooltip='Scan QR'
              style={{
                width: '300px',
                height: '100px',
                marginTop: '100px',
              }}
            >
              <p className='mb-0' style={{fontSize: '24px'}}>
                Start Survey
              </p>
            </Button>
          </div>
        )
      })
    )
  }, [allPolls, handleGoToRating])

  const handleScannerSubmit = useCallback(
    async (value: string) => {
      try {
        const qrcode = new QrCodeDataParser(value)

        const data = await GetCustomerByTicketCodeNew(qrcode.getTicketCode())

        dispatch(actions.saveUser(data.data, qrcode.getTicketCode()))
        const pollCode = allPolls[0]
        goTo(`/polls/${pollCode.code}`)
      } catch (e) {
        // pushError(e)
        const confirmation = await SwalUtils.confirm({
          title: 'Invalid Badge!',
          confirmButtonText: 'Try again',
          cancelButtonText: 'Cancel',
        })
        hideQrScanner()
        if (!confirmation.isConfirmed) {
          goTo('/polls')
        }
      }
    },
    [GetCustomerByTicketCode, pushError, allPolls]
  )

  return (
    <div
      style={{
        backgroundImage: `url(${investopiaBG.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        // background: '#000c7d',
      }}
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
    >
      <div className='px-10 h-100' style={{marginTop: '150px'}}>
        {/* <TicketAuth qrCodemessage='To participate polls, click here to scan your badge'>
          {pollsMemo1}
        </TicketAuth> */}
        {pollsMemo1}
      </div>
      <QRCodeModalInput
        autoSubmit
        hideButton
        hideLabel
        open={isQrScannerOpen}
        onClose={hideQrScanner}
        onSubmit={handleScannerSubmit}
      />
    </div>
  )
}

export {PollsPage}
