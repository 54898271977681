import {useCallback, useState} from 'react'
import {Result} from '@zxing/library'
import {QrReaderVideo} from './QRReaderVideo'
import {MetronicIcon} from '../MetronicIcon'
import {MetronicIconButton} from '../MetronicIconButton'

export interface QRCodeInputProps {
  onChange: (value: string) => void
  className?: string
  scanDelay?: number
  disabled?: boolean
  loading?: boolean
  hasReverse?: boolean
}

export const QRCodeInput = ({
  disabled,
  onChange,
  className,
  scanDelay,
  hasReverse,
}: QRCodeInputProps) => {
  const [cameraEnv, setCameraEnv] = useState<string>('environment')
  const handleResult = useCallback(
    (result: Result) => {
      !disabled && onChange(result.getText())
    },
    [disabled, onChange]
  )

  const handleReverse = useCallback(() => {
    if (cameraEnv === 'user') {
      setCameraEnv('environment')
    } else {
      setCameraEnv('user')
    }
  }, [cameraEnv])

  return (
    <>
      <QrReaderVideo
        className={className}
        constraints={{
          facingMode: cameraEnv,
        }}
        onResult={handleResult}
        scanDelay={scanDelay}
        disabled={disabled}
      />
      {hasReverse && (
        <MetronicIconButton
          className='svg-icon-success svg-icon-1hx mt'
          iconType='General'
          iconName='ReverseCamera'
          onClick={handleReverse}
        />
      )}
    </>
  )
}

const contraints = {facingMode: 'environment'}
