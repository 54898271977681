/* eslint-disable no-lone-blocks */
import {useCallback, useMemo, useState} from 'react'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {ButtonCrumbAttributes} from '../../../../components/ButtonCrumbs/ButtonCrumb'
import {ButtonCrumbs} from '../../../../components/ButtonCrumbs/ButtonCrumbs'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useQueryParam} from '../../../../components/hooks/useQueryParam'
import {Button, ButtonVariant} from '../../../../components/inputs/Button'
import {BaseFileInputValue} from '../../../../components/inputs/FileInput/BaseFileInputValue'
import {FileInputValueCollection} from '../../../../components/inputs/FileInput/FileInputValueCollection'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {
  RegistrationCustomerInformation,
  RegistrationCustomerInformationValues,
  STEP_CUSTOMER_KEYS,
} from './steps/RegistrationCustomerInformation'
import {
  RegistrationPersonalAssistant,
  RegistrationPersonalAssistantValues,
  STEP_ASSISTANT_KEYS,
} from './steps/RegistrationPersonalAssistant'
import {
  RegistrationPersonalInformation,
  RegistrationPersonalInformationValues,
  STEP_INFORMATION_KEYS,
} from './steps/RegistrationPersonalInformation'
import {pick} from 'lodash'
import {MobileNumberParser} from '../../../../utils/MobileNumberParser'
import {useHistory} from 'react-router-dom'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {DateUtil} from '../../../../utils/DateUtil'
import {CustomerModel} from '../../../../models/CustomerModel'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {UpdateRegistration, VerifyRegistrationHash} from '../redux/CustomerRegistrationCRUD'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {EVENT_CODE} from '../../../../../config/env'
import {GetTableCustomFieldsByEvent} from '../../../default/system/redux/SystemCRUD'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {GetEventByCode} from '../../../default/ems/redux/EmsCRUD'
import {EventModel} from '../../../../models/ems/EventModel'
import moment from 'moment-timezone'
import {
  RegistrationCustomerBio,
  RegistrationCustomerBioValues,
  STEP_BIO_KEYS,
} from './steps/RegistrationCustomerBio'
import {
  RichTextEditor,
  RichTextEditorValue,
} from '../../../../components/inputs/RichTextEditor/RichTextEditor'
import {
  CustomFieldCreateValue,
  CustomFieldModel,
  CustomFieldOptionsModel,
} from '../../../../models/system/CustomFieldModel'
// import {FileType} from '../../../../components/inputs/FileInput/FileInput'

enum FormStep {
  CUSTOMER = 'CUSTOMER',
  INFORMATION = 'INFORMATION',
  BIO = 'BIO',
  ASSISTANT = 'ASSISTANT',
}

const FORM_TYPE = 'FORM TYPE'
const EDUCATION_LVL = 'EDUCATION LEVEL'

const StepOrder = [FormStep.CUSTOMER, FormStep.INFORMATION, FormStep.BIO, FormStep.ASSISTANT]

export interface RegistrationFormValues
  extends RegistrationCustomerInformationValues,
    RegistrationCustomerBioValues,
    RegistrationPersonalInformationValues,
    RegistrationPersonalAssistantValues {}

export const Registration = () => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState(FormStep.CUSTOMER)
  const {value: hash} = useQueryParam('hash')
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {staticUrls, organizationCode} = useAppConfig()
  const [degreeFields, setDegreeFields] = useState<CustomFieldOptionsModel[]>([])
  const [customFields, setCustomFields] = useState<CustomFieldModel[]>([])

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      if (hash) {
        try {
          const payload = getPayload(hash, values, customFields)

          await UpdateRegistration(payload)
          setHasSubmitted(true)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    validationSchema,
    validateOnMount: true,
  })

  const resetFormValueFromHash = useCallback(async () => {
    try {
      let formType: any
      let degreeObtain: any
      let event: EventModel | null = null
      if (hash) {
        const {data} = await VerifyRegistrationHash(hash)
        const frmType: any =
          data.guestCustomFieldValues?.length &&
          data?.guestCustomFieldValues.find((_data) => _data.customFieldName === FORM_TYPE)
        const degreeValue: any =
          data.guestCustomFieldValues?.length &&
          data?.guestCustomFieldValues.find((_data) => _data.customFieldName === EDUCATION_LVL)

        if (data.eventCode) {
          const {data: guestCustomFields} = await GetTableCustomFieldsByEvent(
            'ems-custom-field',
            'GUEST',
            data.eventCode
          )
          setCustomFields(guestCustomFields)

          const hasDegreeField = guestCustomFields.find((item) => item.name === EDUCATION_LVL)

          if (hasDegreeField && hasDegreeField.options?.length) {
            setDegreeFields(hasDegreeField.options)
          }
          const {data: eventData} = await GetEventByCode(data.eventCode)
          if (eventData) {
            event = eventData
          }
          if (frmType) {
            formType = guestCustomFields
              .find((_c) => _c.name === FORM_TYPE)
              ?.options?.find((item) => item.code === frmType.value[0])?.name
          }

          if (degreeValue && hasDegreeField && hasDegreeField.options?.length) {
            degreeObtain = hasDegreeField.options.find(
              (item) => item.code === degreeValue.value[0]
            )?.code
          }
        }

        const formData = getFormData(
          organizationCode ? staticUrls.public : `${staticUrls.public}/${data.organization?.code}`,
          data,
          formType || '',
          event,
          degreeObtain || ''
        )

        formik.setValues(formData)
      } else {
        throw new Error('Invalid hash')
      }
    } catch (e: any) {
      pushError(e)
      history.replace('/error/403')
    }
  }, [formik, hash, history, organizationCode, pushError, staticUrls.public])

  const getStepVariant = useCallback(
    (step: FormStep): ButtonVariant => {
      const isPrevious = StepOrder.indexOf(step) < StepOrder.indexOf(currentStep)
      if (isPrevious) {
        // return 'success'
        return 'dark'
      }
      if (currentStep === step) {
        return 'default'
        // return 'secondary'
      }
      return 'default'
      // return 'white'
    },
    [currentStep]
  )

  const crumbs = useMemo((): ButtonCrumbAttributes[] => {
    const def = [
      {
        value: FormStep.CUSTOMER,
        label: <MetronicIcon iconType='General' iconName='User' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.CUSTOMER),
      },
    ]

    if (formik.values.formType.toLocaleUpperCase() === 'SPEAKER') {
      def.push(
        {
          value: FormStep.INFORMATION,
          label: <MetronicIcon iconType='Code' iconName='Info-circle' />,
          className: 'btn-icon',
          variant: getStepVariant(FormStep.INFORMATION),
        },
        {
          value: FormStep.BIO,
          label: <MetronicIcon iconType='Communication' iconName='Write' />,
          className: 'btn-icon',
          variant: getStepVariant(FormStep.BIO),
        },
        {
          value: FormStep.ASSISTANT,
          label: <MetronicIcon iconType='Communication' iconName='Group' />,
          className: 'btn-icon',
          variant: getStepVariant(FormStep.ASSISTANT),
        }
      )
    } else {
      def.push({
        value: FormStep.ASSISTANT,
        label: <MetronicIcon iconType='Communication' iconName='Group' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.ASSISTANT),
      })
    }

    return def
  }, [getStepVariant, formik])

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.CUSTOMER: {
        return <RegistrationCustomerInformation formik={formik} degreeOptions={degreeFields} />
      }
      case FormStep.INFORMATION: {
        return <RegistrationPersonalInformation formik={formik} />
      }
      case FormStep.BIO: {
        return <RegistrationCustomerBio formik={formik} />
      }
      case FormStep.ASSISTANT: {
        return <RegistrationPersonalAssistant formik={formik} />
      }
    }
  }, [currentStep, formik, degreeFields])

  const handleNextClick = useCallback(() => {
    if (formik.values.formType.toLocaleUpperCase() === 'SPEAKER') {
      setCurrentStep((currentStep) => {
        return StepOrder[StepOrder.indexOf(currentStep) + 1]
      })
    } else {
      setCurrentStep((currentStep) => {
        return StepOrder[StepOrder.indexOf(currentStep) + 3]
      })
    }
  }, [formik.values.formType])

  const handlePreviousClick = useCallback(() => {
    if (formik.values.formType.toLocaleUpperCase() === 'SPEAKER') {
      setCurrentStep((currentStep) => {
        return StepOrder[StepOrder.indexOf(currentStep) - 1]
      })
    } else {
      setCurrentStep((currentStep) => {
        return StepOrder[StepOrder.indexOf(currentStep) - 3]
      })
    }
  }, [formik.values.formType])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.CUSTOMER: {
          errors = pick(formik.errors, STEP_CUSTOMER_KEYS)
          break
        }
        case FormStep.INFORMATION: {
          errors = pick(formik.errors, STEP_INFORMATION_KEYS)
          break
        }
        case FormStep.BIO: {
          errors = pick(formik.errors, STEP_BIO_KEYS)
          break
        }
        case FormStep.ASSISTANT: {
          errors = pick(formik.errors, STEP_ASSISTANT_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  const assistantFormHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_ASSISTANT_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasPrevious = currentStepIndex > 0
    const hasNext = currentStepIndex < StepOrder.length - 1
    return (
      <>
        <div className='row mb-3'>
          <div className='col col-xs-6 col-md-6'>
            {hasPrevious && hasNext && (
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            )}
          </div>
          {hasNext && (
            <div className='col col-xs-12'>
              <Button
                className='w-100'
                variant='primary'
                type='button'
                disabled={stepHasErrors(currentStep)}
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
        </div>
        <div className='row g-3'>
          {!hasNext && (
            <div className='col-xs-12 col-md-12'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={assistantFormHasValues || !formik.isValid || formik.isSubmitting}
              >
                Skip and submit
              </Button>
            </div>
          )}
          <div className='col-6 col-md-6'>
            {hasPrevious && !hasNext && (
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            )}
          </div>
          {!hasNext && (
            <div className='col-6 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                // disabled={!formik.isValid || formik.isSubmitting}
                disabled={!assistantFormHasValues || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </>
    )
  }, [
    assistantFormHasValues,
    currentStep,
    formik.isSubmitting,
    formik.isValid,
    handleNextClick,
    handlePreviousClick,
    stepHasErrors,
  ])

  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.CUSTOMER: {
          message = 'Register'
          break
        }
        case FormStep.INFORMATION: {
          message = 'Upload Photo'
          break
        }
        case FormStep.BIO: {
          message = 'Speaker Bio'
          break
        }
        case FormStep.ASSISTANT: {
          return (
            <>
              <h2 className='text-white mb-3'>Enter Your Personal Assistant Details</h2>
              <h2 className='text-white mb-3'>(if applicable)</h2>
            </>
          )
        }
      }
      return <h1 className='text-white mb-3'>{message}</h1>
    }
    return (
      <>
        <img
          alt={'Success registraion'}
          src={toAbsoluteUrl('/media/icons/duotone/General/Checkmark.svg')}
          className='mb-5 h-120px'
        />
        <p className='text-white fw-bold fs-2'>Thank you for completing your registration</p>
        <p className='text-white fw-bold fs-2'>You will receive a confirmation email shortly</p>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

  useOnChange(hash, () => {
    resetFormValueFromHash()
  })

  return (
    <form className='custom-frm-inv' onSubmit={formik.handleSubmit}>
      <div className='text-center'>{formTitle}</div>
      {!hasSubmitted && (
        <div>
          <div className='d-flex justify-content-center mb-5'>
            <ButtonCrumbs crumbs={crumbs} />
          </div>
          {stepForm}
          {actions}
        </div>
      )}
    </form>
  )
}

const getFormData = (
  staticUrl: string,
  customer: Partial<CustomerModel>,
  formType?: string,
  eventData?: EventModel | null,
  degreeCode?: string
): RegistrationFormValues => {
  if (eventData) {
    eventData.endedAt = moment(eventData.endedAt).add(1, 'days').format()
  }
  let customerValues: RegistrationFormValues = {
    salutation: customer.salutation || '',
    // title: '',
    firstName: customer.firstName || INITIAL_VALUES.firstName,
    name: customer.firstName || INITIAL_VALUES.name,
    lastName: customer.lastName || '',
    designation: customer.designation || INITIAL_VALUES.designation,
    type: customer.type || 'guest',
    customerOrganization: customer?.customerOrganization || INITIAL_VALUES.customerOrganization,
    email: customer.email || INITIAL_VALUES.email,
    mobileNumber: customer.mobile || INITIAL_VALUES.mobileNumber,
    dateOfBirth: customer.dob
      ? DateUtil.getDateFromApiString(customer.dob)
      : INITIAL_VALUES.dateOfBirth,
    nationality: customer.nationality || INITIAL_VALUES.nationality,
    passportPhoto: customer.photo
      ? new ImageInputValue(staticUrl, customer.photo)
      : INITIAL_VALUES.passportPhoto,
    // customer.photo ? new ImageInputValue(publicUrl, customer.photo) : null,
    residence: customer.residence || INITIAL_VALUES.nationality,
    personalAssistantName: '',
    personalAssistantLastName: '',
    personalAssistantEmail: '',
    personalAssistantMobileNumber: '',
    passportPhotoCropped: INITIAL_VALUES.passportPhotoCropped,
    passportCopy: INITIAL_VALUES.passportCopy,
    documentExpiry:
      eventData && eventData.endedAt ? new Date(eventData.endedAt) : new Date('March 1, 2025'),
    isFlight: false,
    isHotel: false,
    isTransportation: false,
    flightDetailOne: '',
    flightDetailTwo: '',
    flightCheckinDate: null,
    flightCheckoutDate: null,
    hotelCheckinDate: null,
    hotelCheckoutDate: null,
    transportationDetailOne: '',
    transportationCheckinDate: null,
    formType: formType || INITIAL_VALUES.formType,
    vip: INITIAL_VALUES.vip,
    category: INITIAL_VALUES.category,
    passportNumber: '',
    classType: INITIAL_VALUES.classType,
    reason: INITIAL_VALUES.reason,
    accessType: INITIAL_VALUES.accessType,
    guestCustomFieldValues: customer.guestCustomFieldValues || [],
    eventCode: customer.eventCode || INITIAL_VALUES.eventCode,
    gender: customer.gender || INITIAL_VALUES.gender,
    event: eventData,
    bio: customer.bio
      ? RichTextEditor.getEditorStateFromRaw(JSON.parse(customer.bio))
      : INITIAL_VALUES.bio,
    state: customer.state?.code || INITIAL_VALUES.state,
    degree: degreeCode || INITIAL_VALUES.degree,
    university: '',
    universityLocation: '',
    graduatedYear: INITIAL_VALUES.graduatedYear,
    cityEmpty: formType === 'Speaker' ? true : INITIAL_VALUES.cityEmpty,
  }

  if (customer.personalAssistant) {
    customerValues.personalAssistantName = customer.personalAssistant.firstName || ''
    customerValues.personalAssistantLastName = customer.personalAssistant.lastName || ''
    customerValues.personalAssistantEmail = customer.personalAssistant.email || ''
    customerValues.personalAssistantMobileNumber = customer.personalAssistant.mobile || ''
  }

  if (customer.guestCustomFieldValues?.length) {
    customer.guestCustomFieldValues.map((item) => {
      if (item.customFieldName === 'PASSPORT EXPIRY DATE' && item.value) {
        customerValues.documentExpiry = DateUtil.getDateFromApiString(item.value as string)
      }
      if (item.customFieldName === 'PASSPORT NUMBER' && item.value) {
        customerValues.passportNumber = item.value as string
      }
      if (item.customFieldName === 'ISSUED DATE' && item.value) {
        customerValues.issuedDate = DateUtil.getDateFromApiString(item.value as string)
      }
      if (item.customFieldName === 'SCHOOL OR UNIVERSITY NAME' && item.value) {
        customerValues.university = item.value as string
      }
      if (item.customFieldName === 'SCHOOL OR UNIVERSITY LOCATION (CITY)' && item.value) {
        customerValues.universityLocation = item.value as string
      }
      if (item.customFieldName === 'YEAR OF GRADUATION' && item.value) {
        customerValues.graduatedYear = item.value as string
      }
      return item
    })
  }
  return customerValues
}

const getPayload = (
  hash: string,
  customer: RegistrationFormValues,
  customFields: CustomFieldModel[]
) => {
  const formData = new FormData()
  const photo = customer.passportPhoto?.toApiValue()
  // const photoCropped = customer.passportPhotoCropped?.toApiValue()
  formData.append('salutation', customer.salutation)
  formData.append('hash', hash)
  formData.append('firstName', customer.firstName)
  formData.append('lastName', customer.lastName)
  formData.append('name', `${customer.firstName} ${customer.lastName}`)
  formData.append('email', customer.email)
  formData.append('mobile', customer.mobileNumber)
  formData.append('residence', customer.residence || '')
  formData.append('stateCode', customer.state)
  formData.append('designation', customer.designation)
  formData.append('customerOrganization', customer.customerOrganization)
  formData.append('gender', customer.gender)
  formData.append('nationality', customer.nationality || '')
  formData.append(
    'bio',
    customer.bio ? JSON.stringify(RichTextEditor.getRawValueFromEditorState(customer.bio)) : ''
  )
  // formData.append('bio', customer.bio || '')
  if (customer.formType.toLocaleUpperCase() === 'SPEAKER') {
    customer.documentExpiry &&
      formData.append('documentExpiry', DateUtil.convertDateToApiString(customer.documentExpiry))
    customer.passportNumber && formData.append('passportNumber', customer.passportNumber)
    // photoCropped && formData.append('photo', photoCropped, 'photo.png')
    // photo && formData.append('documents', photo, 'photo.png')
    photo && formData.append('photo', photo, 'photo.png')
    customer.passportCopy.getFileArray().forEach((file) => {
      const passport = file.toApiValue()
      passport && formData.append('documents', passport)
    })
  }
  if (customer.guestCustomFieldValues.length) {
    let customFieldValues: CustomFieldCreateValue[] = customer.guestCustomFieldValues

    for (const _item of customFields) {
      const found = customFieldValues.findIndex((item) => item.customFieldName === _item.name)
      if (_item.name === 'PASSPORT EXPIRY DATE') {
        if (found > -1) {
          customFieldValues[found].value = customer.documentExpiry
        } else {
          customFieldValues.push({
            customFieldCode: _item.code,
            customFieldName: _item.name,
            value: customer.documentExpiry,
          })
        }
      }
      if (_item.name === 'ISSUED DATE') {
        if (found > -1) {
          customFieldValues[found].value = customer.issuedDate as Date
        } else {
          customFieldValues.push({
            customFieldCode: _item.code,
            customFieldName: _item.name,
            value: customer.issuedDate as Date,
          })
        }
      }
      if (_item.name === 'PASSPORT NUMBER') {
        if (found > -1) {
          customFieldValues[found].value = customer.passportNumber
        } else {
          customFieldValues.push({
            value: customer.passportNumber,
            customFieldCode: _item.code,
            customFieldName: _item.name,
          })
        }
      }
      if (_item.name === 'EDUCATION LEVEL') {
        if (found > -1) {
          customFieldValues[found].value = [customer.degree]
        } else {
          customFieldValues.push({
            value: [customer.degree],
            customFieldCode: _item.code,
            customFieldName: _item.name,
          })
        }
      }
      if (_item.name === 'SCHOOL OR UNIVERSITY NAME') {
        if (found > -1) {
          customFieldValues[found].value = customer.university
        } else {
          customFieldValues.push({
            value: customer.university,
            customFieldCode: _item.code,
            customFieldName: _item.name,
          })
        }
      }
      if (_item.name === 'SCHOOL OR UNIVERSITY LOCATION (CITY)') {
        if (found > -1) {
          customFieldValues[found].value = customer.universityLocation
        } else {
          customFieldValues.push({
            value: customer.universityLocation,
            customFieldCode: _item.code,
            customFieldName: _item.name,
          })
        }
      }
      if (_item.name === 'YEAR OF GRADUATION') {
        if (found > -1) {
          customFieldValues[found].value = customer.graduatedYear
        } else {
          customFieldValues.push({
            value: customer.graduatedYear,
            customFieldCode: _item.code,
            customFieldName: _item.name,
          })
        }
      }
    }
    formData.append(`guestCustomFields`, JSON.stringify(customFieldValues))
  }

  formData.append(`personalAssistant[firstName]`, customer.personalAssistantName)
  formData.append(`personalAssistant[lastName]`, customer.personalAssistantLastName)
  formData.append(`personalAssistant[email]`, customer.personalAssistantEmail)
  formData.append(`personalAssistant[mobile]`, customer.personalAssistantMobileNumber)

  return formData
}

const INITIAL_VALUES: RegistrationFormValues = {
  salutation: '',
  firstName: '',
  email: '',
  name: '',
  lastName: '',
  mobileNumber: '',
  dateOfBirth: null,
  designation: '',
  type: 'guest',
  nationality: '',
  residence: '',
  customerOrganization: '',
  passportPhoto: null,
  passportPhotoCropped: null,
  documentExpiry: null,
  passportCopy: new FileInputValueCollection<BaseFileInputValue>(),
  personalAssistantName: '',
  personalAssistantLastName: '',
  personalAssistantEmail: '',
  personalAssistantMobileNumber: '',
  isFlight: false,
  isHotel: false,
  isTransportation: false,
  flightDetailOne: '',
  flightDetailTwo: '',
  flightCheckinDate: null,
  flightCheckoutDate: null,
  hotelCheckinDate: null,
  hotelCheckoutDate: null,
  transportationDetailOne: '',
  transportationCheckinDate: null,
  formType: 'Delegate',
  vip: 'NO',
  category: 'PARTICIPANT',
  passportNumber: '',
  classType: '',
  reason: '',
  accessType: 'FORUMSRT',
  guestCustomFieldValues: [],
  eventCode: EVENT_CODE,
  gender: '',
  bio: null,
  state: '',
  issuedDate: null,
  degree: '',
  university: '',
  universityLocation: '',
  graduatedYear: '0',
  cityEmpty: false,
}

const validationSchema = yup.object().shape({
  salutation: yup.string().required(),
  email: yup.string().required().email('Please enter a valid email'),
  firstName: yup.string(),
  lastName: yup.string(),
  mobileNumber: yup
    .string()
    .required()
    .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
      if (value) {
        const mobileNumber = new MobileNumberParser(value)
        return mobileNumber.isValidMobileNumber()
      }
      return true
    })
    .required('Please enter a valid number'),
  designation: yup.string().required(),

  nationality: yup.string().required(),
  residence: yup.string().required(),
  state: yup.string().when('cityEmpty', {
    is: true,
    then: yup.string().required('Please select city'),
  }),
  // degree: yup.string().when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup.string().required('Please select degree'),
  // }),
  // graduatedYear: yup.string().when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup
  //     .string()
  //     .required('Please input graduated year')
  //     .min(4, 'Minimum 4 characters')
  //     .max(4, 'Minimum 4 characters'),
  // }),
  // university: yup.string().when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup.string().required('Please input university'),
  // }),
  // universityLocation: yup.string().when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup.string().required('Please input university graduation'),
  // }),
  customerOrganization: yup.string().required(),
  // issuedDate: yup.mixed()
  // .when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup.date().required('Please input expiry date'),
  // }),
  documentExpiry: yup.mixed().when('formType', {
    is: (val: any) => val === 'Speaker',
    then: yup.date().required('Please input expiry date'),
  }),
  // passportNumber: yup.string(),
  // .when('formType', {
  //   is: (val: any) => val === 'Speaker',
  //   then: yup.string().required('Please input your passport number'),
  // }),
  bio: yup.mixed().when('formType', {
    is: (val: any) => val === 'Speaker',
    then: yup.mixed<RichTextEditorValue>().test('hasValue', 'Please enter Bio', (value) => {
      if (value) {
        return RichTextEditor.hasText(value)
      }
      return false
    }),
  }),
  passportPhoto: yup
    .object()
    .nullable()
    .when('formType', {
      is: 'Speaker',
      then: yup.object().required('Please submit your photo').typeError('Please submit your photo'),
    }),
  // passportCopy: yup
  //   .mixed()
  //   .nullable(),
    // .when('formType', {
    //   is: (val: any) => val === 'Speaker',
    //   then: yup
    //     .object()
    //     .required('Please submit your photo')
    //     .test('has-value', 'Invalid file', (value) => {
    //       if (value instanceof FileInputValueCollection) {
    //         // const acceptedTypes = [FileType.PDF, FileType.JPEG, FileType.PNG]
    //         // let isValid = true

    //         // value.getFileArray().forEach((file) => {
    //         //   if (!acceptedTypes.includes(file.file.type)) {
    //         //     isValid = false
    //         //   }
    //         // })

    //         // return isValid
    //         return value.hasValue()
    //       }
    //       return false
    //     }),
    // }),
  personalAssistantName: yup.string(),
  personalAssistantLastName: yup.string(),
  personalAssistantEmail: yup.string().notRequired().email('Please enter a valid email'),
  personalAssistantMobileNumber: yup
    .string()
    .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
      if (value) {
        const mobileNumber = new MobileNumberParser(value)
        return mobileNumber.isValidMobileNumber()
      }
      return true
    }),
})
