import {PollQuestionsRatingModel} from '../../../../models/eva/PollRatingModel'
import {getAnswerValueAndDisability} from '../../hooks/usePolls'

interface InputParams {
  answerCode: string
  questionCode: string
  name: string
  answerText: string
  type: string
  result: PollQuestionsRatingModel[]
  disabled?: boolean
  onChange: (questionCode: string, answerCode: string) => void
}

const InputRatingPolls = ({
  result,
  answerCode,
  questionCode,
  name,
  answerText,
  type,
  disabled,
  onChange,
}: InputParams) => {
  return (
    <div className='d-flex gap-2'>
      <div>
        <input
          id={answerCode}
          className='form-check-input ms-0 mt-1'
          style={{
            borderColor: '#203245',
            width: '28px',
            height: '28px',
          }}
          name={name}
          type={type}
          checked={getAnswerValueAndDisability(questionCode, answerCode, result)[0]}
          onChange={() => onChange(questionCode, answerCode)}
          disabled={disabled}
        />
      </div>
      <label htmlFor={answerCode} className='form-check-label ms-3' style={{fontSize: '24px'}}>
        {answerText}
      </label>
    </div>
  )
}

export default InputRatingPolls
